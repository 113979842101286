//jquery
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

//cb-dx public methods
import { moveEditColumnToLeft, cell_prepared } from '../JS/dx-data-grid-edit-icons';
window.moveEditColumnToLeft = moveEditColumnToLeft;
window.cell_prepared = cell_prepared;

//browser compatibility public methods
import { get_browser, isBrowserSupported } from '../JS/browser-compatibility';
window.get_browser = get_browser;
window.isBrowserSupported = isBrowserSupported;

//cookie public methods
import { readCookie, createCookie, eraseCookie } from '../JS/cookie-helper';
window.readCookie = readCookie;
window.createCookie = createCookie;
window.eraseCookie = eraseCookie;

//string html decoder method
import ReplaceEndcodedStringWithDecodedVals from '../JS/stringdecoder';
window.ReplaceEndcodedStringWithDecodedVals = ReplaceEndcodedStringWithDecodedVals;

//tab toggle public methods
import '../JS/tab-toggle';

//for legacy promises (use fetch for react components https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch)
import getJSON from '../JS/promise';
window.getJSON = getJSON;

//bootsrap
import '@popperjs/core';
import 'bootstrap';

import validate from 'jquery-validation';
window.validate = validate;

import unobtrusive from 'jquery-validation-unobtrusive';
window.unobtrusive = unobtrusive;

$(function () {
    $("[data-toggle='tooltip']").tooltip();
});

//https://fontawesome.com/how-to-use/on-the-web/using-with/react //TODO
//styles
import '../Styles/scss/main.scss';

function ReplaceEndcodedStringWithDecodedVals(string) {
    string = string.replace(/&amp;/g, '&'); //&
    string = string.replace(/&gt;/g, '>'); //>
    string = string.replace(/&lt;/g, '<'); //<
    string = string.replace(/&quot;/g, '"'); //"
    string = string.replace(/&#39;/g, "'"); //'
    return string;
}

export default ReplaceEndcodedStringWithDecodedVals;

import $ from 'jquery';

let currentTabId = null;

$(function () {
    $('#cb-header-toggler').on('click', function () {
        let collapsableDivId = $(this).attr('href');
        let toExpand = !$(collapsableDivId).hasClass('show');

        $('a[name*="cb-toggler-card-nav-link"]').each(function (i, obj) {
            let navLink = $(obj);
            //we are collapsed so lets expand
            if (toExpand) {
                //show selected tab
                if (currentTabId !== null) {
                    if (navLink.attr('id') === currentTabId) {
                        navLink.addClass('active');
                        $(navLink.attr('href')).tab('show');
                    }
                } else {
                    //show first tab
                    if (i === 0) {
                        let tabId = navLink.attr('href');
                        navLink.addClass('active');
                        $(tabId).tab('show');
                    }
                }
                navLink.attr('aria-selected', true);
                navLink.attr('data-toggle', 'tab');
            } else {
                //lets collapse
                navLink.removeClass('active');
                navLink.attr('aria-selected', false);
                navLink.removeAttr('data-toggle');
            }
        });
        //Close and collapse all divs
        if (toExpand === false) {
            let tabContent = $(collapsableDivId).find('.tab-content');
            tabContent.find('.tab-pane').each(function (i, obj) {
                let navLink = $(obj);
                navLink.removeClass('active');
                navLink.removeClass('show');
            });
        }
    });
    $('a[name*="cb-toggler-card-nav-link"]').on('click', function () {
        currentTabId = $(this).attr('id');
    });
});

function moveEditColumnToLeft(dataGrid) {
    dataGrid.columnOption('command:edit', {
        visibleIndex: -1,
        width: 80,
    });
}
function cell_prepared(e) {
    if (e.rowType === 'data' && e.column.command === 'edit') {
        var isEditing = e.row.isEditing,
            $links = e.cellElement.find('.dx-link');

        $links.text('');

        if (isEditing) {
            $links
                .filter('.dx-link-save')
                .addClass('devextreme-icon text-success fas fa-check-circle text-decoration-none');
            $links
                .filter('.dx-link-cancel')
                .addClass('devextreme-icon text-danger fas fa-times-circle text-decoration-none');
        } else {
            $links
                .filter('.dx-link-edit')
                .addClass('devextreme-icon text-primary fas fa-pencil-alt text-decoration-none');
            $links.filter('.dx-link-delete').addClass('devextreme-icon text-danger fas fa-trash text-decoration-none');
        }
    }
}

export { cell_prepared, moveEditColumnToLeft };
